import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "breakpoints"
    }}>{`Breakpoints`}</h1>
    <p>{`The breakpoints are based on the Bootstrap 5 grid, see docs `}<a parentName="p" {...{
        "href": "https://getbootstrap.com/docs/5.0/"
      }}>{`here`}</a></p>
    <h2 {...{
      "id": "available-breakpoints"
    }}>{`Available breakpoints`}</h2>
    <p>{`Bootstrap includes six default breakpoints, sometimes referred to as `}<em parentName="p">{`grid tiers`}</em>{`, for building responsively.`}</p>
    <table style={{
      "maxWidth": "500px"
    }}>
  <thead>
    <tr>
      <th>Breakpoint</th>
      <th>Class infix</th>
      <th>Dimensions</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>X-Small</td>
      <td><em>None</em></td>
      <td>&lt;576px</td>
    </tr>
    <tr>
      <td>Small</td>
      <td>sm</td>
      <td>&ge;576px</td>
    </tr>
    <tr>
      <td>Medium</td>
      <td>md</td>
      <td>&ge;768px</td>
    </tr>
    <tr>
      <td>Large</td>
      <td>lg</td>
      <td>&ge;992px</td>
    </tr>
    <tr>
      <td>Extra large</td>
      <td>xl</td>
      <td>&ge;1200px</td>
    </tr>
    <tr>
      <td>Extra extra large</td>
      <td>xxl</td>
      <td>&ge;1400px</td>
    </tr>
  </tbody>
    </table>
    <p>{`Each breakpoint was chosen to comfortably hold containers whose widths are multiples of 24.
Breakpoints are also representative of a subset of common device sizes and viewport dimensions—they don't specifically target every use case or device.
Instead, the ranges provide a strong and consistent foundation to build on for nearly any device.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      